import React from "react"

// Components
import Seo from "components/seo"
import Hero from "../../components/hero"

// Page Sections
import VideoList from "../screens/resources/media-coverage/videos-list"
import Newsroom from "../screens/resources/newsroom"

const MediaCoverage = () => {
  return (
    <React.Fragment>
      <Hero pageTitle="Media Coverage">
        <p>Broadcast interviews and video segments showcasing Visby&nbsp;Medical in the media.</p>
      </Hero>
      <VideoList />
      <Newsroom />
    </React.Fragment>
  )
}
export default MediaCoverage

export const Head = () => (
  <Seo
        title="Media Coverage"
        description="Broadcast interviews and video segments showcasing Visby Medical in the media."
        image="/meta/news-media-coverage.jpg"
  />
)
