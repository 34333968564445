import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Glide from "@glidejs/glide"

// Stylesheets
import "@glidejs/glide/dist/css/glide.core.min.css"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

// Icons
import ArrowPrev from "assets/icons/icon-arrow-left.inline.svg"
import ArrowNext from "assets/icons/icon-arrow-right.inline.svg"

const StyledNewsroom = styled.section`
  padding: 60px 0;
  background-color: ${colors.purpleDark};
  color: ${colors.white};
  text-align: center;
  border-bottom: 1px solid ${colors.charcoal};

  ${breakpoint.medium`
    padding: 124px 0;
  `}

  h2 {
    margin-bottom: 56px;

    ${breakpoint.medium`
      margin-bottom: 80px;
    `}
  }

  .glide__slides .glide__slide {
    height: auto !important;
  }

	.glide__arrows {
		display: block;
	}

  .newsroom-post {
    height: 100%;
    display: block;
    padding: 48px 28px;
    background-color: ${colors.white};
    border: 1px solid ${colors.charcoal};

    &:focus {
      outline: 2px solid ${colors.charcoal};
    }

    .logo {
      margin-bottom: 26px;

      ${breakpoint.medium`
        margin-bottom: 36px;
      `}
    }

    .quote {
      color: ${colors.purpleDark};
      font-size: 17px;
      font-weight: 700;
      padding-bottom: 0;

      ${breakpoint.medium`
        font-size: 20px;
        padding-bottom: 20px;
      `}

      &::before {
        content: "“";
      }

      &::after {
        content: "”";
      }
    }
  }

  .bullets {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    ${breakpoint.medium`
      margin-top: 32px;
    `}

    .bullet {
      width: 13px;
      height: 13px;
      position: relative;
      display: flex;
      margin: 0 7px 0 0;
      border-radius: 50%;
      transition: all 0.3s;
      cursor: pointer;

      &::before {
        content: "";
        width: 7px;
        height: 7px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        border: 1px solid ${colors.white};
        border-radius: 50%;
      }

      &:hover,
      &.glide__bullet--active {
        background-color: ${colors.white};
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .glide__arrow {
    display: none;

    ${breakpoint.medium`
      position: absolute;
      top: calc((100% - 96px) / 2);
      display: flex;

      &--right {
        right: -58px;
      }

      &--left {
        left: -58px;
      }
    `}
  }
`

const NewsroomCarousel = props => {
  const data = useStaticQuery(graphql`
    query {
      allWpNewsroom {
        nodes {
          id
          newsroom {
            quote
            link
            pressLogo {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className="glide" ref={props.myRef}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {data.allWpNewsroom.nodes.map((news) => (
            <li className="glide__slide" key={news.id}>
              <a
                key={news.id}
                className="newsroom-post"
                href={news.newsroom.link}
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="logo">
                <GatsbyImage
                  image={getImage(news.newsroom.pressLogo.localFile)}
                  width={200}
                  formats={["webp"]}
                  alt="News"
                />
                </div>
                <p className="quote">{news.newsroom.quote}</p>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="bullets glide__bullets" data-glide-el="controls[nav]">
        {data.allWpNewsroom.nodes.map((news, index) => (
          <button
            key={news.id}
            type="bullet"
            className="bullet"
            data-glide-dir={"=" + index}
            aria-label="bullet"
          ></button>
        ))}
      </div>
      <div className="glide__arrows" data-glide-el="controls">
        <button className="glide__arrow glide__arrow--left" data-glide-dir="<">
          <ArrowPrev />
        </button>
        <button className="glide__arrow glide__arrow--right" data-glide-dir=">">
          <ArrowNext />
        </button>
      </div>
    </div>
  )
}

class Newsroom extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  componentDidMount = () => {
    const carousel = new Glide(this.myRef.current, {
      type: "carousel",
      autoplay: 3000,
      perView: 3,
      gap: 42,
      breakpoints: {
        1023: {
          perView: 2,
        },
        767: {
          perView: 1,
          gap: 0,
        },
      },
    })

    carousel.mount()
  }

  render = props => (
    <StyledNewsroom>
      <Container data-aos="fade-up">
        <h2>In the Press</h2>
        <NewsroomCarousel myRef={this.myRef} />
      </Container>
    </StyledNewsroom>
  )
}

export default Newsroom
